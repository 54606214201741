/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  CardBody,
  CardHeader,
} from 'reactstrap';
import './style.scss';
// import { useDispatch, useSelector } from 'react-redux';

import QuestionsList from './components/QuestionsList';
import AnswerForm from './components/AnswerForm';
import ViewSubmitedAnswer from './components/ViewSubmitedAnswer';
import UnderReviewPopupAskMeAny from './components/UnderReviewPopupAskMeAny';
import infoicon from '../../assets/svgs/info.svg';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { getAskMeAnyUnansweredCount } from '../../actions/actionAskMeAnything';

// import { decrementAskMeAnyUnanswerCount } from '../../actions/actionAskMeAnything';

const AskMeAnything = ({
  perfCharacterObjid, getAskMeAnyQuestions,
  createAskMeAnyAns,
  createAskMeAnyAnswerMedia,
  askMeAnythingBonusCredits,
  askMeAnythingBonusAnswerLimit,
}) => {
  const [questions, setQuestions] = useState([]);
  const [hover, setHover] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [submitedAnswer, setSubmitedAnswer] = useState(null);
  const [isUnderReview, setIsUnderReview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisable] = useState(false);
  const [questionCount, setQuestionCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();
  // const unanswerCount = useSelector(state => state.unansweredCounts?.data);

  const getAllQuestions = () => {
    setIsLoading(true);
    const quesApiUrl = `/cstquestions/list/?character_objid=${perfCharacterObjid}&status=approved|modified_approved&page=${currentPage}&page_size=25&ordering=-objid`;
    getAskMeAnyQuestions(quesApiUrl).then((resp) => {
      const { results, count } = resp.value.data;
      setQuestions(results && results);
      setIsLoading(false);
      setQuestionCount(count);
    }).catch(err => console.log(err));
  };

  useEffect(() => {
    getAllQuestions();
  }, [currentPage]);

  const onMouseEnterHandler = () => {
    setHover(true);
  };

  const onMouseLeaveHandler = () => {
    setHover(false);
  };

  const cancelAnswerForm = () => {
    setSelectedQuestion(null);
    setSubmitedAnswer(null);
  };

  const submitAnswerForm = (answerData) => {
    // dispatch(incrementAskMeAnyUnanswerCount(unanswerCount));
    // dispatch(decrementAskMeAnyUnanswerCount(unanswerCount));
    setIsSubmitDisable(true);
    const allImages = answerData.quesPhoto;
    const uploadImgApiCall = (image, ansObjID) => {
      const mediaFormData = new FormData();
      mediaFormData.append('media_file', image);
      mediaFormData.append('cstquestionanswermedia_2cstanswers', ansObjID);
      createAskMeAnyAnswerMedia(mediaFormData).then(() => {
      }).catch(err => console.log(err));
    };

    const formData = new FormData();
    formData.append('cstanswers_2perfcharacter', perfCharacterObjid);
    formData.append('cstanswers_2cstquestion', answerData.quesId);
    formData.append('answer_text', answerData.quesAns);
    // Calling Answer create api.
    createAskMeAnyAns(formData).then((ansResp) => {
      setIsSubmitDisable(false);
      const { objid: ansObjID } = ansResp?.value?.data;
      dispatch(getAskMeAnyUnansweredCount(perfCharacterObjid));
      // if we get answer objid than call create ans media api.
      if (ansObjID) {
        allImages.forEach((image) => {
          uploadImgApiCall(image, ansObjID);
        });
      }
      getAllQuestions();
      setIsUnderReview(true);
      setSelectedQuestion(null);
      setSubmitedAnswer(null);
    }).catch(err => console.log(err));
  };

  const closeUnderReviewPopupHandler = () => {
    setTimeout(() => {
      setIsUnderReview(false);
    }, 3000);
  };

  const cancelSubmitedAnswerView = () => {
    setSelectedQuestion(null);
    setSubmitedAnswer(null);
  };

  const formatDateTimeHandler = (date) => {
    const formatedDate = moment(date).format('DD.MM.YYYY');
    return formatedDate;
  };

  const renderAskMeAnythingInfo = () => (
    <div className="ask-me-anything-info_section">
      <p className="m-0">
        Customers can submit questions for hosts like you to answer.
        You can craft your response and even attach media to enhance your answer.
        If your answer is selected, it will be published on your profile and a dedicated
        page with a link to your profile. Each approved answer earns you &nbsp;
        {askMeAnythingBonusCredits}
        &nbsp;
        credits!
        Up to &nbsp;
        {askMeAnythingBonusAnswerLimit}
        &nbsp;
        answers from different hosts can be selected per question,
        so share your insights and stand out!
      </p>
    </div>
  );

  return (
    <Card className="bg-none mt-3">
      <CardHeader className="p-0 ask-me-anything-header">
        <h2>
          <span className="headTitle">Ask Me Anything</span>
        </h2>
        <span
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
          className="cursor-pointer"
        >
          <img src={infoicon} alt="info icon" />
        </span>
        {hover ? renderAskMeAnythingInfo() : ''}
      </CardHeader>
      <CardBody className="bg-dark card card-body pt-0 pb-0">
        {isLoading && <div><LoadingSpinner /></div>}
        {submitedAnswer ? (
          <ViewSubmitedAnswer
            submittedAns={submitedAnswer}
            onCancelSubmittedAnswer={() => cancelSubmitedAnswerView()}
            formatDateTimeFun={formatDateTimeHandler}
          />
        ) : selectedQuestion
          ? (
            <AnswerForm
              selectedQuestionForAns={selectedQuestion}
              onCancelAnswerForm={cancelAnswerForm}
              onSubmitAnswerForm={submitAnswerForm}
              formatDateTimeFun={formatDateTimeHandler}
              isSubmitDisabled={isSubmitDisabled}
            />
          )
          : (
            <QuestionsList
              questions={questions}
              questionCount={questionCount}
              onAnswerClicked={question => setSelectedQuestion(question)}
              onSubmittedQueClick={question => setSubmitedAnswer(question)}
              formatDateTimeFun={formatDateTimeHandler}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )
        }
        <UnderReviewPopupAskMeAny
          open={isUnderReview}
          closeUnderReviewPopup={closeUnderReviewPopupHandler}
        />
      </CardBody>
    </Card>
  );
};

AskMeAnything.defaultProps = {
  perfCharacterObjid: '',
  askMeAnythingBonusCredits: '',
  askMeAnythingBonusAnswerLimit: '',
};

AskMeAnything.propTypes = {
  perfCharacterObjid: PropTypes.string,
  getAskMeAnyQuestions: PropTypes.func.isRequired,
  createAskMeAnyAns: PropTypes.func.isRequired,
  createAskMeAnyAnswerMedia: PropTypes.func.isRequired,
  askMeAnythingBonusCredits: PropTypes.string,
  askMeAnythingBonusAnswerLimit: PropTypes.string,
};

export default AskMeAnything;
