/* eslint-disable react/no-unused-state */
/* eslint-disable camelcase */
import React from 'react';
import { Row, Col, Alert } from 'reactstrap';
import PropTypes from 'prop-types';

import CreditsContainer from '../../containers/CreditsContainer';
import StatusCardContainer from '../../containers/StatusCardContainer';
import EngagmenstScoreContainer from '../../containers/EngagementScoreContainer';
import CreditsCard from './components/CreditsCard';
import MessagesCard, { MessageCardProps } from './components/MessagesCard';
// import RankingCard, { RankingCardProps } from './components/RankingCard';
import MyReviewsCard, { MyReviewsCardProps } from './components/MyReviewsCard';
import BodyHeader from '../../components/BodyHeader';
// import InteractionsCard, { InteractionsCardProps } from './components/InteractionsCard';
// import PurchasesCard, { PurchaseCardProps } from './components/PurchasesCard';
import LoadingSpinner from '../../utils/LoadingSpinner';
import './style.scss';


class Dashboard extends React.PureComponent {
  state = {
    notificationEnabled: false,
    isEdgeBrowser: false,
    visible: false,
    showLink: false,
  };


  componentDidMount() {
    const {
      // getAllCharacters,
      getMessages,
      getMyReviews,
      getPerformer,
      characterId,
      performerId,
      perf2cstcontactId,
      getSysConfig,
    } = this.props;
    const url = 'cfgsysconfig/list/?config_names_in=Credit_to_USD,MIN_CHAR_LIMIT,MINIMUM_BALANCE_REQUEST_PAYMENT,PERF_INIT_CHAT_MIN_MSG_PAID,MIN_IMAGE_PURCHASE_CREDITS,MIN_VIDEO_PURCHASE_CREDITS,MIN_AUDIO_PURCHASE_CREDITS,ROULETTE_SCREEN_TIMEOUT_PERFORMER,min_voice_call_rate,max_voice_call_rate,min_video_call_rate,max_video_call_rate,WHISPERER_TIMEOUT,is_chatbot_enabled,AMA_BONUS_CREDITS,AMA_BONUS_ANSWER_LIMIT';

    if (characterId > 0) {
      getMyReviews(characterId);
    }
    if (performerId > 0) {
      getPerformer(performerId);
    }
    getMessages(perf2cstcontactId);
    // getAllCharacters();
    getSysConfig(url);
    // this.askNotificationPermission();

    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notifications.');
      return;
    }

    // Check if notifications permission is granted
    if (Notification.permission === 'denied' || Notification.permission === 'default') {
      if (Notification.permission === 'default') {
        this.setState({ showLink: true });
      }

      if (Notification.permission === 'denied') {
        this.setState({ showLink: false });
      }

      // If permissions are not granted, check local storage if user has disabled notifications
      localStorage.setItem('notificationEnabled', false);
      this.setState({ notificationEnabled: false, visible: true });
    }

    // this.requestNotificationPermission();
    const { userAgent } = window.navigator;
    if (userAgent.includes('Edg') || userAgent.includes('Chrome')) {
      this.requestNotificationPermission();
    }
    if (userAgent.includes('Edg') || userAgent.includes('Safari') || userAgent.includes('Chrome')) {
      if ('Notification' in window) {
        this.setState({ isEdgeBrowser: true });
      }
    } else {
      this.askNotificationPermission();
    }
    window.scrollTo(0, 0);
  }


  onDismiss = () => {
    this.setState({ visible: false });
  }

  checkNotificationPromise = () => {
    try {
      Notification.requestPermission().then();
    } catch (e) {
      return false;
    }
    return true;
  };


  requestNotificationPermission = () => {
    // Request permission for notifications
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        this.setState({ notificationEnabled: true });
        localStorage.setItem('notificationEnabled', true);
      } else {
        this.setState({ notificationEnabled: false });
        localStorage.setItem('notificationEnabled', false);
      }
    });
  };

  askNotificationPermission = () => {
    const { setChatNotificationPermission } = this.props;
    // function to actually ask the permissions
    const handlePermission = () => {
      // set the button to shown or hidden, depending on what the user answers
      if (Notification.permission === 'denied' || Notification.permission === 'default') {
        setChatNotificationPermission(Notification.permission);
      } else {
        setChatNotificationPermission(Notification.permission);
      }
    };

    // Let's check if the browser supports notifications
    if (('Notification' in window)) {
      if (this.checkNotificationPromise()) {
        Notification.requestPermission()
          .then((permission) => {
            handlePermission(permission);
          });
      } else {
        Notification.requestPermission((permission) => {
          handlePermission(permission);
        });
      }
    } else {
      console.log('This browser does not support notifications.');
    }
  };

  render() {
    const {
      notificationEnabled, isEdgeBrowser, visible, showLink,
    } = this.state;
    const {
      // rankingsData,
      loadingData,
      // purchaseData,
      systemMessages,
      reviews,
      // interactionsData,
      headerData,
      history,
      unReadSystemMessageCount,
    } = this.props;

    if (loadingData) {
      return <LoadingSpinner />;
    }

    return (
      <>

        <div>
          {isEdgeBrowser && !notificationEnabled && (
            <Alert className="text-danger my-4 text-center dashboard-notify-alert" color="danger" isOpen={visible} toggle={this.onDismiss}>
              {showLink ? (
                <div>
                  {/* To receive chat notifications, click */}
                  <span
                    className="cursor-pointer text-underline pl-1"
                    role="presentation"
                  // onClick={this.requestNotificationPermission}
                  >
                    To receive chat notifications,
                    please enable notifications permission in your browser settings.
                  </span>
                  {/* , and then click ALLOW. */}
                </div>
              ) : (
                <div>
                  To receive chat notifications,
                  please enable notifications permission in your browser settings.
                </div>
              )}
              {' '}

            </Alert>
          )}
        </div>


        <Row className="mb-3 topHeaderRanking mt-3">
          <BodyHeader data={headerData} />
        </Row>

        <Row>
          <Col md={7} className="mb-3">
            <Row>
              <Col md={5} className="mb-3">
                <StatusCardContainer
                  history={history}
                />
              </Col>
              <Col md={7} className="mb-3">
                <CreditsContainer
                  component={CreditsCard}
                  history={history}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <MessagesCard
                  messages={systemMessages}
                  count={unReadSystemMessageCount}
                />
              </Col>
            </Row>
          </Col>
          <Col md={5} className="mb-3">
            <Row>
              <Col md={12} className="mb-3">
                <EngagmenstScoreContainer />
              </Col>
              <Col md={12}>
                <MyReviewsCard reviews={reviews} history={history} />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Row>
          <Col xs={5}>
            <Row>
              <Col className="mb-3">
                <MessagesCard messages={systemMessages} />
              </Col>
            </Row>

            <Row>
              <Col>
                <MyReviewsCard reviews={reviews} history={history} />
              </Col>
            </Row>
          </Col>

          <Col xs={7} className="mb-3 pl-md-0">
            <RankingCard data={rankingsData} />
          </Col>
        </Row> */}

        {/* <Row>
          <Col xs={7} className="mb-3">
            <InteractionsCard data={interactionsData} />
          </Col>

          <Col xs={5} className="mb-3 pl-md-0">
            <PurchasesCard purchases={purchaseData} />
          </Col>
        </Row> */}
      </>
    );
  }
}

Dashboard.defaultProps = {
  loadingData: false,
  characterId: -1,
  videoChatRequest: {
    name: 'N/A',
    requested: true,
  },
  headerData: {},
  unReadSystemMessageCount: 0,
};

Dashboard.propTypes = {
  loadingData: PropTypes.bool,
  getMyReviews: PropTypes.func.isRequired,
  // rankingsData: RankingCardProps.isRequired,
  getMessages: PropTypes.func.isRequired,
  // getAllCharacters: PropTypes.func.isRequired,
  systemMessages: MessageCardProps.isRequired,
  reviews: MyReviewsCardProps.isRequired,
  getSysConfig: PropTypes.func.isRequired,
  // purchaseData: PurchaseCardProps.isRequired,
  // interactionsData: InteractionsCardProps.isRequired,
  getPerformer: PropTypes.func.isRequired,
  characterId: PropTypes.number,
  performerId: PropTypes.number.isRequired,
  perf2cstcontactId: PropTypes.number.isRequired,
  setChatNotificationPermission: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  history: PropTypes.object.isRequired,
  videoChatRequest: PropTypes.shape({
    name: PropTypes.string,
    requested: PropTypes.bool,
  }),
  headerData: PropTypes.instanceOf(Object),
  unReadSystemMessageCount: PropTypes.number,
};

export default Dashboard;
